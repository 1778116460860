// DashboardHome.js
import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, ResponsiveContainer } from 'recharts';
import axios from 'axios'; 
// import '../../tailwind.css';

const DashboardHome = () => {
  const [dashboardData, setDashboardData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchDashboardData = async () => {
      try {
        const response = await axios.get('http://localhost:3500/api/saver/dashboard');
        setDashboardData(response.data);
        setLoading(false);
      } catch (err) {
        setError('🚫Error fetching dashboard data 🚫');
        setLoading(false);
      }
    };

    fetchDashboardData();
  }, []);

  if (loading) return <div>Loading...</div>;
  if (error) return <div>{error}</div>;
  if (!dashboardData) return null;

  const chartData = Object.entries(dashboardData.annualContributions).map(([year, value]) => ({
    year,
    value,
  }));

  return (
    <div className="tailwind-styles">
      <div className="flex h-screen bg-gray-100">
        {/* Sidebar */}
        <div className="w-64 bg-white shadow-md">
          <div className="p-4">
            <img src="/path-to-kiota-logo.png" alt="Kiota" className="h-8" />
          </div>
          <nav className="mt-8">
            <a href="#" className="flex items-center px-4 py-2 bg-gray-200 text-gray-700">
              <span className="mr-2">◻️</span> Dashboard
            </a>
            <a href="#" className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100">
              <span className="mr-2">$</span> Investment
            </a>
            <a href="#" className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100">
              <span className="mr-2">📄</span> Statement
            </a>
            <a href="#" className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100">
              <span className="mr-2">👤</span> Profile
            </a>
            <a href="#" className="flex items-center px-4 py-2 text-gray-600 hover:bg-gray-100">
              <span className="mr-2">⚙️</span> More
            </a>
          </nav>
        </div>

        {/* Main content */}
        <div className="flex-1 overflow-y-auto">
          {/* Top bar */}
          <div className="bg-white shadow-sm">
            <div className="container mx-auto px-4 py-3 flex justify-between items-center">
              <div className="flex items-center">
                <input type="text" placeholder="Search" className="px-3 py-1 border rounded-md" />
              </div>
              <div className="flex items-center">
                <span className="mr-2">Maren Aminoff</span>
                <img src="/path-to-profile-pic.jpg" alt="Profile" className="w-8 h-8 rounded-full" />
              </div>
            </div>
          </div>

          {/* Dashboard content */}
          <div className="container mx-auto px-4 py-8">
            <h1 className="text-2xl font-semibold mb-6">Overview Dashboard</h1>

            <div className="grid grid-cols-3 gap-8">
              {/* Chart section */}
              <div className="col-span-2 bg-white rounded-lg shadow p-6">
                <h2 className="text-lg font-semibold mb-4">Annual Contributions (Last 5 years)</h2>
                <ResponsiveContainer width="100%" height={300}>
                  <LineChart data={chartData}>
                    <XAxis dataKey="year" />
                    <YAxis />
                    <Line type="monotone" dataKey="value" stroke="#FFA500" strokeWidth={2} />
                  </LineChart>
                </ResponsiveContainer>
              </div>

              {/* Right sidebar */}
              <div className="space-y-6">
                <div className="bg-white rounded-lg shadow p-6">
                  <h2 className="text-sm text-gray-600 mb-2">I am invested in</h2>
                  <p className="text-lg font-semibold text-brown-600">{dashboardData.investmentStrategy}</p>
                  <a href="#" className="text-orange-600 text-sm mt-2 inline-block">Manage Investments →</a>
                </div>

                <div className="bg-white rounded-lg shadow p-6">
                  <h2 className="text-lg font-semibold mb-4">Personal Details</h2>
                  <div className="space-y-2">
                    <div className="flex justify-between">
                      <span className="text-gray-600">Full name:</span>
                      <span>{dashboardData.personalDetails.fullName}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-600">Mobile number:</span>
                      <span>{dashboardData.personalDetails.mobile}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-600">Address:</span>
                      <span>{dashboardData.personalDetails.address}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-600">KRA PIN:</span>
                      <span>{dashboardData.personalDetails.kraPin}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-600">ID No.:</span>
                      <span>{dashboardData.personalDetails.idNumber}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-600">Company:</span>
                      <span>{dashboardData.company ? dashboardData.company.name : '-'}</span>
                    </div>
                    <div className="flex justify-between">
                      <span className="text-gray-600">Company No.:</span>
                      <span>{dashboardData.company ? dashboardData.company.registration_number : '-'}</span>
                    </div>
                  </div>
                  <a href="#" className="text-orange-600 text-sm mt-4 inline-block">Profile details →</a>
                </div>

                <div className="bg-white rounded-lg shadow p-6">
                  <h2 className="text-sm text-gray-600 mb-2">My beneficiary is,</h2>
                  <p className="text-lg font-semibold">{dashboardData.beneficiary.name}</p>
                  <p className="text-sm text-gray-600">{dashboardData.beneficiary.relationship}</p>
                  <a href="#" className="text-orange-600 text-sm mt-2 inline-block">Change beneficiary →</a>
                </div>
              </div>
            </div>

            {/* Bottom cards */}
            <div className="grid grid-cols-3 gap-8 mt-8">
              <div className="bg-white rounded-lg shadow p-6">
                <div className="text-yellow-500 text-3xl mb-2">📊</div>
                <h3 className="text-2xl font-semibold mb-1">KES {dashboardData.estimatedRetirementProjection.toLocaleString()}</h3>
                <p className="text-sm text-gray-600">Estimated retirement projection</p>
              </div>
              <div className="bg-white rounded-lg shadow p-6">
                <div className="text-yellow-500 text-3xl mb-2">💳</div>
                <h3 className="text-2xl font-semibold mb-1">KES {dashboardData.currentBalance.toLocaleString()}</h3>
                <p className="text-sm text-gray-600">Current Balance</p>
              </div>
              <div className="bg-white rounded-lg shadow p-6">
                <div className="text-yellow-500 text-3xl mb-2">🤲</div>
                <h3 className="text-2xl font-semibold mb-1">KES {dashboardData.lastContribution ? dashboardData.lastContribution.amount.toLocaleString() : '0'}</h3>
                <p className="text-sm text-gray-600">Last Contribution</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DashboardHome;
