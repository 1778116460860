import React, { useState } from "react";
import "../styles/Learn.css";
import { Link } from "react-router-dom";
import vector from "../assets/Vector.png";
import learnRight from "../assets/Education-right.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRight } from "@fortawesome/free-solid-svg-icons";
import Kiotacalculator from "../components/Kiotacalculator";
import { colors, duration } from "@mui/material";

const DiffSavingRetirementPlant = () => {
  return (
    <div className="difference">
      <p>
        <strong>Savings plan :</strong>A strategy for setting aside money for
        short-term or long-term goals
      </p>
      <p>
        <strong>Retirement plan :</strong>A strategy specifically designed to
        provide income after an individual retires from work.
      </p>
    </div>
  );
};

const BlogCards = ({ imgSrc, title, duration, linkPath = "" }) => {
  return (
    <div className="card">
      <Link to={linkPath}>
        <img src={imgSrc} alt="learn" />
      </Link>
      <h1>{title}</h1>
      <p>{duration} min read</p>
      <Link to={linkPath} className="schedule-demo-link">
        Read More{" "}
        <FontAwesomeIcon icon={faArrowRight} className="custom-icon" />
      </Link>
    </div>
  );
};

const DisplayBlogCard = () => {
  const blogs = [
    {
      imgSrc: learnRight,
      title: "learnRight",
      duration: 4,
      classname_: "one",
      linkPath: "#",
    },
    {
      imgSrc: learnRight,
      title: "Savings 101",
      duration: 5,
      classname_: "two",
      linkPath: "",
    },
    {
      imgSrc: learnRight,
      title: "Just starting with retirement?",
      duration: 7,
      classname_: "three",
      linkPath: "",
    },
    {
      imgSrc: learnRight,
      title: "Spotlight on Kenya",
      duration: 3,
      classname_: "four",
      linkPath: "",
    },
    {
      imgSrc: learnRight,
      title: "Grow your wealth now!",
      duration: 4,
      classname_: "five",
      linkPath: "",
    },
    {
      imgSrc: learnRight,
      title: "Savings & Investments",
      duration: 6,
      classname_: "six",
      linkPath: "",
    },
  ];

  return (
    <div className="highlights">
      {blogs.map((blog, index) => (
        <div className={blog.classname_} key={index}>
          <BlogCards
            imgSrc={blog.imgSrc}
            title={blog.title}
            duration={blog.duration}
            linkPath={blog.linkPath}
          />
        </div>
      ))}
    </div>
  );
};

const Learn = () => {
  const [showMore, setShowMore] = useState(false);

  return (
    <div className="education">
      <div className="top-lefti">
        <Link to="">
          <img src={vector} alt="vector" />
        </Link>
        <h1>Insights & Education</h1>
        <p>Keeping you at the forefront of wealth planning</p>
      </div>
      <div className="hover-calculator">
        <Kiotacalculator />
      </div>
      <section className="learn-main">
        <div className="card">
          <div className={`left-card ${showMore ? "expanded" : ""}`}>
            <h1>
              What's the Difference Between a Savings <br></br> and a Retirement
              Plan?
            </h1>
            {showMore && <DiffSavingRetirementPlant />}
            <button
              className="btn btn-primary"
              onClick={() => setShowMore(!showMore)}
            >
              {!showMore ? "Show more" : "Show less"}
            </button>
          </div>
          <div className="right-card">
            <Link to="">
              <img src={learnRight} alt="learn" />
            </Link>
          </div>
        </div>
      </section>
      <div className="card-display">
        <h1> Let's Learn</h1>
      </div>
      <DisplayBlogCard />
      <div className="next">
        <Link to="">
          <button>{"View More"}</button>
        </Link>
      </div>
      <div className="rectangle-box">
        <div className="rect"></div>
        <h2>Gain value through Kiota savings tools</h2>
        <Link to="/kiota-demo">
          {" "}
          <button>Get a Demo</button>
        </Link>
      </div>
    </div>
  );
};
export default Learn;
