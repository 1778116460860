import React from "react";
import EmailFrame from "./EmailFrame.js";
import "../styles/KiotaLogin.css";

const Login = () => {
  return (
    <div className="kiota-login">
     
      <section className="kiota-logo-frame">
        <div className="kiota-logo-1-container">
          <a href="/">
            <img
              className="kiota-logo-14"
              loading="eager"
              alt=""
              src="/kiota-logo-11.svg"
            />
          </a>

        </div>
        <div className="grid-icons-dropdown-frame">
          <EmailFrame />
          <div>
          <img
            className="pattern-icon"
            loading="eager"
            alt=""
            src="/pattern@2x.png"
          /> 
          </div>
        </div>
      </section>
    </div>
  );
};

export default Login;