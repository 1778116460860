import "../styles/EmailFrame.css";
import React, { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
// import { faCaretDown } from '@fortawesome/free-solid-svg-icons';

const EmailFrame = () => {
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [selectedOption, setSelectedOption] = useState('');

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  return (
    <div className="email-frame">
      <div className="frame-employee">
        <div className="benefits-frame">
          <div className="vector-support-frame">
            <h1 className="putting-you-on">
              Putting you on the path towards financial health
            </h1>
          </div>
          <img
            className="vector-analysis-frame"
            loading="eager"
            alt=""
            src="/vector3.svg"
          />
        </div>
      </div>
      <form className="login-group">
        <div className="log-in-label">
          <h3 className="log-in3">Log in</h3>
        </div>

        <div className="log-in-label1">
          <div className="rectangle-parent20">
          <select value={selectedOption} onChange={handleOptionChange} className="rectangle-parent20"
          style = {{border: 'none', outline: 'none', marginLeft: '10px', marginRight: '10px', cursor: 'pointer'}}
          >
                <option value="theSaver">Employee</option>
                <option value="employer">Employer</option>
                <option value="tpa">Saver</option>
                <option value="tpa">TPA</option>
              </select>
          </div>
        </div>

 <div className="retirement-plan-group">
          <div className="circle-frame">
            <div className="rectangle-parent21">
              <div className="frame-child28" />
              <div className="k-e-s-text">
                <input
                  className="email"
                  placeholder="Email"
                  type="email"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="retirement-plan-group">
          <div className="circle-frame">
            <div className="rectangle-parent21">
              <div className="frame-child29" />
              <div className="k-e-s-text">
                <input
                  className="password"
                  placeholder="Password"
                  type={passwordVisible ? "text" : "password"}
                />
                <FontAwesomeIcon
                  icon={passwordVisible ? faEyeSlash : faEye}
                  className="invisible-1-icon"
                  onClick={togglePasswordVisibility}
                />
              </div>
            </div>
            <a href="/forgot-password" className="forgot-password">Forgot password ?</a>
          </div>
        </div>
        <button className="log-in-label3">
          <div className="login">Login</div>
        </button>
      </form>
    </div>
  );
};

export default EmailFrame;